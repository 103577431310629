import React, { useEffect, useState } from 'react'
import DownloadIcon from '../components/SVG/DownloadIcon.js'

const VacanciesComponent = () => {
  const [vacancies, setVacancies] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchVacancies = async () => {
      const apiURL = `https://fortehealth001.cdn.prismic.io/api/v2`

      try {
        // Fetch the API metadata to get the master ref
        const metaResponse = await fetch(apiURL)
        const meta = await metaResponse.json()
        const ref = meta.refs.find(r => r.id === 'master').ref

        // Fetch content from Prismic
        const contentResponse = await fetch(
          `${apiURL}/documents/search?ref=${ref}&q=[[at(document.type,"work")]]`
        )
        const content = await contentResponse.json()

        const vacanciesData = content.results[0]?.data?.vacancies || []
        setVacancies(vacanciesData)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }

    fetchVacancies()
  }, [])

  if (error || (!vacancies.length && !loading))
    return <div>There are no vacancies at the moment.</div>

  return (
    <div>
      {loading && <div>Loading...</div>}
      {vacancies?.map((vacancy, index) => (
        <div
          key={index}
          className={`${
            index < (vacancies ? vacancies.length : 0) - 1
              ? 'mb-5 pb-6 border-grey-light border-b'
              : 'mb-5 pb-6'
          }`}
        >
          {vacancy.vacancy_title && (
            <h3 className="text-lg font-medium">{vacancy.vacancy_title}</h3>
          )}
          {vacancy.vacancy_description && (
            <p className="mb-2 pt-3 leading-lg">
              {vacancy.vacancy_description}
            </p>
          )}
          <div className="flex items-center mt-5">
            {vacancy.vacancy_link && vacancy.vacancy_link.url && (
              <a
                href={vacancy.vacancy_link.url}
                className="px-3 py-2 bg-white no-underline text-grey w-auto text-sm"
              >
                Apply Now
              </a>
            )}
            {vacancy.vacancy_download && vacancy.vacancy_download.url && (
              <a
                href={vacancy.vacancy_download.url}
                className="flex px-3 py-2 border-white border ml-6 no-underline text-white text-sm"
              >
                <p className="pr-2">Download Job Description</p>
                <DownloadIcon />
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default VacanciesComponent
